async function getUserFolders(user, token = '') {
  const baseurl = process.env.NODE_ENV === 'development' ? '//discovers.local' : '.';
  
  try {
    const response = await fetch(`${baseurl}/api/folders.php?user=${user}&token=${token}`);
    if(!response.ok) {
      throw(response.status);
    }

    const data = await response.json();

    if(data.items === 0) {
      throw(204);
    }

    return {
      success: true,
      data: data,
    };
  }
  catch(status) {
    let error;

    switch(status) {
      default:
        error = `Something went wrong. Bummer, dude.`;
        break;
      case 204:
        error = `That user's collection is empty ¯\\_(ツ)_/¯`;
        break;
      case 403:
        error = `That user's collection is set to private.`;
        break;
      case 404:
        error = `That user doesn't exist.`;
        break;
      case 420:
        error = `Too many active requests. Please wait a minute and try again.`;
        break;
    }

    return {
      success: false,
      error: error,
    };
  }
}

export default getUserFolders;
