import styles from './Popup.module.css';

const PopupAuth = (props) => {
  const {
    onContinue,
    onCancel
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.window}>
        <div className={styles.inner}>
          <p>To retrieve your collection folders, Discovers needs to<br />
          access your Discogs.com account.</p>
          <p>Authorization is stored securely in your web browser.<br />
          Discovers can only access your collection while you are actively using this app, and you can revoke access at any time via your Discogs.com <a href="https://www.discogs.com/settings/applications" target="_blank">account settings</a>.</p>
          <div className={`${styles.buttons} ${styles.buttons_double}`}>
            <button 
              type="button"
              className={styles.continue}
              onClick={onContinue}>
              Continue
            </button>
            <button 
              type="button"
              className={styles.cancel}
              onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupAuth;
