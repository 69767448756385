import React from 'react';
import PopupAuthBegin from './PopupAuthBegin';
import PopupAuthComplete from './PopupAuthComplete';
import getUserFolders from './helpers/getUserFolders';
import getUserReleases from './helpers/getUserReleases';
import getCollectionFilters from './helpers/getCollectionFilters';
import getImagePortionSize from './helpers/getImagePortionSize';
import getRemoteImage from './helpers/getRemoteImage';
import styles from './App.module.css';

const LOAD_ITEM_THROTTLE = 333;
const MAX_ITEMS = 10000;
const MAX_CANVAS_SIZE = 10000;
const ITEMS_PER_PAGE = 100;
const MAX_REQUESTS = MAX_ITEMS / ITEMS_PER_PAGE;

class App extends React.Component {
  constructor() {
    super();
    this.canvasRef = React.createRef();
    this.downloadRef = React.createRef();

    this.state = {
      userName: '',
      token: '',
      status: '',
      error: '',
      popupAuthBegin: false,
      popupAuthComplete: false,
      loading: false,
      sort: 'artist',
      releasesTotal: 0,
      releasesLoaded: 0,
      imagesLoaded: 0,
      pageCurrent: 0,
      pageTotal: 0,
      releases: [],
      folders: [],
      folder: 0,
      formats: [],
      format: '',
      subformats: [],
      subformat: '',
      genres: [],
      genre: '',
      artists: [],
      artist: '',
      canvasSize: 0,
      imageSize: 150,
    }

    this.handleUserName = this.handleUserName.bind(this);
    this.handleFolder = this.handleFolder.bind(this);
    this.handleFormat = this.handleFormat.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleGenre = this.handleGenre.bind(this);
    this.handleArtist = this.handleArtist.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleAuthBegin = this.handleAuthBegin.bind(this);
    this.handleAuthContinue = this.handleAuthContinue.bind(this);
    this.handleAuthCancel = this.handleAuthCancel.bind(this);
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if(params.get('auth_approved') && params.get('token')) {
      window.history.replaceState('', '', './');
      const userName = params.get('auth_approved');
      localStorage.setItem(
        `discovers_${userName}`, 
        params.get('token')
      );
      this.setState({
        userName: userName,
      }, () => {
        this.handleSubmit();
        this.handleAuthComplete();
      });
    }
    else if(params.get('auth_denied')) {
      window.history.replaceState('', '', './');
      // TODO - reload previous state
    }
  };

  handleUserName(event) {
    this.setState({
      userName: event.target.value,
    });
  };

  handleFolder(event) {
    this.setState({
      folder: event.target.value,
    }, () => {
      this.drawCanvas();
    });
  }

  handleFormat(event) {
    this.setState({
      format: event.target.value,
      subformat: '',
    }, () => {
      this.drawCanvas();
    });
  };

  handleSize(event) {
    this.setState({
      subformat: event.target.value,
    }, () => {
      this.drawCanvas();
    });
  };

  handleGenre(event) {
    this.setState({
      genre: event.target.value,
    }, () => {
      this.drawCanvas();
    });
  };

  handleArtist(event) {
    this.setState({
      artist: event.target.value,
    }, () => {
      this.drawCanvas();
    });
  };

  handleAuthBegin = () => {
    this.setState({
      popupAuthBegin: true,
    });
  }

  handleAuthContinue() {
    const baseurl = process.env.NODE_ENV === 'development' ? '//discovers.local' : '.';
    window.location = `${baseurl}/api/auth_request_token.php`;
  }

  handleAuthCancel() {
    this.setState({
      popupAuthBegin: false,
      popupAuthComplete: false,
    });
  }

  handleAuthComplete() {
    this.setState({
      popupAuthComplete: true,
    });
  }

  handleSubmit(event) {
    if(event) {
      event.preventDefault();
    }
    
    const canvas = this.canvasRef.current;
    canvas.width = 0;
    canvas.height = 0;
    this.setState({
      status: 'getUser',
      loading: true,
      releasesTotal: 0,
      releasesLoaded: 0,
      imagesLoaded: 0,
      error: '',
      format: '',
      genre: '',
    }, () => {
      this.getReleases();
    });
  };

  handleReset() {
    window.location = window.location;
  }

  handleDownload() {
    const link = document.createElement('a');
    link.download = `discovers_${this.state.userName}.png`;
    this.canvasRef.current.toBlob((blob) => {
      link.href = URL.createObjectURL(blob);
      link.click();
    })
  }

  getReleases() {
    const {
      userName,
    } = this.state;

    const token = localStorage.getItem(`discovers_${userName}`) || '';

    getUserFolders(userName, token)
    .then(result => {
      if(result.success) {
        const { 
          folders, 
          items, 
          auth,
        } = result.data;

        if(!auth) {
          // User has revoked authorization
          localStorage.removeItem(`discovers_${userName}`);
        }

        this.setState({
          folders: folders,
          releasesTotal: items,
        }, () => {
          const requests = [];
          const requestFolders = folders.length > 1 ? folders.slice(1) : folders;
          requestFolders.forEach(folder => {
            const pages = Math.ceil(folder.items / ITEMS_PER_PAGE);
            for(let page = 1; page <= pages; page++) {
              requests.push({
                folder: folder.id,
                query: `user=${userName}&token=${auth ? token : ''}&folder=${folder.id}&page=${page}`,
              });
            }
          });

          this.loadReleases(requests.slice(0, MAX_REQUESTS), 0, []);
        });
      }
      else {
        this.setState({
          status: 'error',
          error: result.error,
          loading: false,
        });
      }
    });
  }

  loadReleases(requests, index = 0, releases = []) {
    getUserReleases(requests[index].query, requests[index].folder)
    .then(result => {
      releases.push(...result.releases);

      this.setState({
        status: 'getCollection',
        releasesLoaded: this.state.releasesLoaded + result.releases.length,
      });

      index+= 1;
      if(index < requests.length) {
        setTimeout(() => {
          this.loadReleases(requests, index, releases);
        }, LOAD_ITEM_THROTTLE);
      }
      else {
        setTimeout(() => {
          const filters = getCollectionFilters(releases);
          this.setState({
            status: 'getImages',
            loading: true,
            releases: releases,
            formats: filters.formats,
            subformats: filters.subformats,
            genres: filters.genres,
            artists: filters.artists,
          }, () => {
            this.drawCanvas();
          });
        }, 1000);
      }
    });
  }

  drawCanvas() {
    const { 
      releases,
      folder,
      format,
      subformat,
      genre,
      artist,
      sort,
    } = this.state;

    let sorted = releases.filter((item) => {
      let display = true;
      
      if(parseInt(folder) !== 0 && parseInt(item.folder) !== parseInt(folder)) {
        display = false;
      }
      else if(format !== '' && item.format !== format) {
        display = false;
      }
      else if(subformat !== '' && item.subformat.replace(/^0+/, '') !== subformat) {
        display = false;
      }
      else if(artist !== '' && item.artist !== artist) {
        display = false;
      }
      else if(genre !== '' && !item.genres.includes(genre)) {
        display = false;
      }

      return display;
    });

    switch(sort) {
      case 'artist':
        sorted = sorted.sort((a, b) => `${a.artist} ${a.title}`.localeCompare(`${b.artist} ${b.title}`));
        break;
    }

    let canvasSize, canvasWidth, canvasHeight, imageSize;
    if(this.state.canvasSize === 0) {
      canvasSize = Math.min(
        MAX_CANVAS_SIZE, 
        Math.ceil(Math.sqrt(sorted.length)) * this.state.imageSize
      );
    }
    else {
      canvasSize = this.state.canvasSize;
    }

    canvasWidth = canvasSize;
    canvasHeight = canvasSize;
    imageSize = getImagePortionSize(sorted.length, canvasSize, canvasSize);

    // Remove empty row
    if(Math.round(Math.sqrt(sorted.length)) * imageSize < canvasHeight) {
      canvasHeight-= imageSize;
    }

    const canvas = this.canvasRef.current;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight + 20;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'white';
    ctx.font = '10px monospace';
    ctx.fillText('discovers.antisound.net', 0, canvasHeight + 13);

    let x = 0, y = 0, imagesLoaded = 0, timer = 0;
    sorted.forEach(async (item, index) => {
      timer+= item.image ? 0 : LOAD_ITEM_THROTTLE;
      setTimeout(async () => {
        imagesLoaded+= 1;

        if(item.image_thumb) {
          const image = item.image || await getRemoteImage(item.image_thumb);
          sorted[index].image = image;
          ctx.drawImage(image, 0, 0, image.width, image.height, x, y, imageSize, imageSize);
        }
        
        if(Math.ceil(x) < canvasWidth - imageSize) {
          x+= imageSize;
        }
        else {
          x = 0;
          y+= imageSize;
        }

        this.setState({
          releases: releases,
          imagesLoaded: imagesLoaded,
        });

        if(imagesLoaded === releases.length) {
          this.setState({
            status: 'complete',
            loading: false,
          });
        }
      }, timer);
    });
  };

  render() {
    const {
      status,
      loading,
      error,
      popupAuthBegin,
      popupAuthComplete,
      releasesLoaded,
      imagesLoaded,
      releasesTotal,
      userName,
      folders,
      folder,
      formats,
      format,
      subformats,
      subformat,
      genres,
      genre,
      artists,
      artist,
    } = this.state;
    
    return (
      <div
        className={styles.root}>
        <form
          className={styles.form}
          onSubmit={this.handleSubmit}>
          <ul>
            <li className={styles.username}>
              <input 
                type="text"
                name="userName"
                value={userName}
                title="Discogs Username"
                placeholder="Discogs Username"
                onChange={this.handleUserName}
                readOnly={status !== ''}
                required
              />
            </li>
            {status === '' && (
              <>
                <li className={styles.submit}>
                  <button
                    type="submit"
                    disabled={loading}
                  >
                    Go
                  </button>
                </li>
              </>
            )}
            {status !== '' && (
              <>
                <li className={styles.reset}>
                  <button
                    type="reset"
                    onClick={this.handleReset}
                  >
                    Reset
                  </button>
                </li>
              </>
            )}
            {status === 'complete' && (
              <>
                {folders.length === 1 && (
                  <li className={styles.folders}>
                    <button
                      type="button"
                      onClick={this.handleAuthBegin}>
                      Folder
                    </button>
                  </li>
                )}
                {folders.length > 1 && (
                  <li className={styles.folder}>
                    <select
                      name="folder"
                      title="Folder"
                      value={folder}
                      onChange={this.handleFolder}>
                      {folders.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.id === 0 ? 'Folder' : item.name}
                        </option>
                      ))}
                    </select>
                  </li>
                )}
                <li className={styles.filter}>
                  <select
                    name="format"
                    title="Format"
                    value={format}
                    onChange={this.handleFormat}>
                    <option value="">Format</option>
                    {formats.map((item, index) => (
                      <option key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </li>
                <li className={styles.filter}>
                  <select
                    name="subformat"
                    title="Size"
                    value={subformat}
                    onChange={this.handleSize}>
                    <option value="">Size</option>
                    {subformats.map((item, index) => (
                      <option key={index}>
                        {item.replace(/^0+/, '')}
                      </option>
                    ))}
                  </select>
                </li>
                <li className={styles.filter}>
                  <select
                    name="genre"
                    title="Genre"
                    value={genre}
                    onChange={this.handleGenre}>
                    <option value="">Genre</option>
                    {genres.map((item, index) => (
                      <option key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </li>
                <li className={styles.filter}>
                  <select
                    name="artist"
                    title="Artist"
                    value={artist}
                    onChange={this.handleArtist}>
                    <option value="">Artist</option>
                    {artists.map((item, index) => (
                      <option key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </li>
              </>
            )}
          </ul>
        </form>
        <div className={styles.output}>
          <div className={styles.message}>
            {status === '' && (
              <div>
                <p>An album art visualiser for your Discogs.com collection.</p>
                <p>If items or images are not loading due to Discogs limits, just wait a minute and try again.</p>
              </div>
            )}
            {status === 'error' && (
              <div>
                <p>{error}</p>
              </div>
            )}
            {status === 'getUser' && (
              <div>
                <p>Fetching collection&hellip;</p>
              </div>
            )}
            {status === 'getCollection' && (
              <div>
                <p>Loading releases&hellip; {releasesLoaded} of {releasesTotal}</p>
              </div>
            )}
            {status === 'getImages' && (
              <div>
                <p>Loading images&hellip; {imagesLoaded} of {releasesTotal}</p>
              </div>
            )}
            {status === 'complete' && (
              <div>
                <p>Finished!</p>
                <p>Use the menus above to filter your collection.</p>
                <p>Click/tap to download image.</p>
              </div>
            )}
          </div>
          <div className={styles.image}>
            <a 
              ref={this.downloadRef} 
              className={styles.download}
              download={`${userName}.png`}
            >
              <canvas 
                ref={this.canvasRef}
                className={styles.canvas}
                data-complete={status === 'complete'}
                onClick={this.handleDownload}
                width={0}
                height={0}
              >
              </canvas>
            </a>
          </div>
          {loading && (
            <div className={styles.loading}>
              <img src="loading.svg" alt="" />
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <span>&copy; 2024 <a href="mailto:stk@antisound.net?subject=DisCovers">Skye Klein</a>.</span>
          <span><a href="https://paypal.me/metapatch" target="_blank">Send me a tip</a> or <a href="https://antisound.net/releases" target="_blank">buy my music</a> ❤</span>
        </div>
        {popupAuthBegin && (
          <PopupAuthBegin
            onContinue={this.handleAuthContinue}
            onCancel={this.handleAuthCancel}
          />
        )}
        {popupAuthComplete && (
          <PopupAuthComplete
            onContinue={this.handleAuthCancel}
          />
        )}
      </div>
    );
  };
};

export default App;
