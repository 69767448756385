async function getUserReleases(query, folder) {
  const baseurl = process.env.NODE_ENV === 'development' ? '//discovers.local' : '.';
  const response = await fetch(`${baseurl}/api/collection.php?${query}`);
  const data = await response.json();

  return {
    items: data.items,
    releases: data.releases.map(item => {
      item.folder = folder;
      return item;
    }),
  };
}

export default getUserReleases;
