const getRemoteImage = async (url) => {
  const baseurl = process.env.NODE_ENV === 'development' ? '//discovers.local' : '.';
  const parsed = new URL(url);
  const response = await fetch(`${baseurl}/api/image.php?image=${parsed.pathname}`);
  const data = await response.json();

  return new Promise((resolve) => { 
    let image = new Image(); 
    image.onload = () => {
      resolve(image);
    };
    image.src = data.src;
  });
}

export default getRemoteImage;
