const getCollectionFilters = (releases) => {
  let formats = [];
  let subformats = [];
  let genres = [];
  let artists = [];

  releases.forEach(release => {
    formats.push(release.format);
    subformats.push(release.subformat);
    artists.push(release.artist);
    genres.push(...release.genres);
  });

  return {
    formats: [...new Set(formats)].sort(),
    subformats: [...new Set(subformats)].sort(),
    genres: [...new Set(genres)].sort(),
    artists: [...new Set(artists)].sort(),
  };
};

export default getCollectionFilters;
