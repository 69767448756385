import styles from './Popup.module.css';

const PopupAuthComplete = (props) => {
  const {
    onContinue,
  } = props;

  return (
    <div className={styles.root}>
      <div className={styles.window}>
        <div className={styles.inner}>
          <p>Thanks for authorizing Discovers!</p>
          <p>Discovers is now re-indexing your collection, including any folders you have created.</p>
          <p>You can now close this popup.</p>
          <div className={`${styles.buttons} ${styles.buttons_single}`}>
            <button 
              type="button"
              className={styles.continue}
              onClick={onContinue}>
              Groovy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupAuthComplete;
